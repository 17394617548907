export default function ScanIcon() {
    return (<svg id="_20X20_photo_Back" data-name="20X20 photo Back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
        <g id="Mask_Group_385" data-name="Mask Group 385">
            <g id="barcode-scanner">
                <path id="Path_21641" data-name="Path 21641" d="M19.583,5.833a.417.417,0,0,1-.417-.417V2.083a1.252,1.252,0,0,0-1.25-1.25H14.583a.417.417,0,1,1,0-.833h3.333A2.086,2.086,0,0,1,20,2.083V5.417A.417.417,0,0,1,19.583,5.833Z" fill="#f7f7f7" />
                <path id="Path_21642" data-name="Path 21642" d="M17.917,20H14.583a.417.417,0,1,1,0-.833h3.333a1.252,1.252,0,0,0,1.25-1.25V14.583a.417.417,0,1,1,.833,0v3.333A2.086,2.086,0,0,1,17.917,20Z" fill="#f7f7f7" />
                <path id="Path_21643" data-name="Path 21643" d="M5.417,20H2.083A2.086,2.086,0,0,1,0,17.917V14.583a.417.417,0,1,1,.833,0v3.333a1.252,1.252,0,0,0,1.25,1.25H5.417a.417.417,0,0,1,0,.833Z" fill="#f7f7f7" />
                <path id="Path_21644" data-name="Path 21644" d="M.417,5.833A.417.417,0,0,1,0,5.417V2.083A2.086,2.086,0,0,1,2.083,0H5.417a.417.417,0,0,1,0,.833H2.083a1.252,1.252,0,0,0-1.25,1.25V5.417A.417.417,0,0,1,.417,5.833Z" fill="#f7f7f7" />
                <path id="Path_21645" data-name="Path 21645" d="M14.583,15.833H5.417a1.252,1.252,0,0,1-1.25-1.25v-2.5a.417.417,0,1,1,.833,0v2.5A.417.417,0,0,0,5.417,15h9.167A.417.417,0,0,0,15,14.583v-2.5a.417.417,0,1,1,.833,0v2.5A1.252,1.252,0,0,1,14.583,15.833Z" fill="#f7f7f7" />
                <path id="Path_21646" data-name="Path 21646" d="M15.417,9.167A.417.417,0,0,1,15,8.75V5.417A.417.417,0,0,0,14.583,5H5.417A.417.417,0,0,0,5,5.417V8.75a.417.417,0,0,1-.833,0V5.417a1.252,1.252,0,0,1,1.25-1.25h9.167a1.252,1.252,0,0,1,1.25,1.25V8.75A.417.417,0,0,1,15.417,9.167Z" fill="#f7f7f7" />
                <path id="Path_21647" data-name="Path 21647" d="M17.083,12.5H2.917a.417.417,0,0,1,0-.833H17.083a.417.417,0,1,1,0,.833Z" fill="#f7f7f7" />
            </g>
        </g>
    </svg>
    )
}