const initialState = {
    categories: [],
    viewedList: [],
    ref: false

};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CATEGORIES_REDUCER": {
            return ({
                ...state,
                categories: action.payload
            })
        }
        case "ADD_LAYOUT": {

            return ({
                ...state,
                viewedList: [...action.layout],
                ref: !state.ref
            })
        }
        case "CLEAR_LAYOUT": {
            return ({
                ...state,
                viewedList: [],
                ref: !state.ref
            })
        }
        default:
            return { ...state };
    }

};

export default categoriesReducer;



