export const SIGNUP_SAGA = "SIGNUP_SAGA";
export const SIGNUP_REDUCER = "SIGNUP_REDUCER";
export const FORGET_SAGA = "FORGET_SAGA";
export const FORGET_REDUCER = "FORGET_REDUCER";
export const GET_COUNTRIES_SAGA = "GET_COUNTRIES_SAGA";
export const WAIT = "WAIT";
export const DONE = "DONE";
export const ERROR = "ERROR";
export const SMS = "SMS";
export const VERIFY = "VERIFY";
