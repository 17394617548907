export default function ChatIcon() {
    return (<svg id="_20X20_photo_Back" data-name="20X20 photo Back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_4536" data-name="Rectangle 4536" width="20" height="20" fill="none" />
            </clipPath>
        </defs>
        <g id="Mask_Group_217" data-name="Mask Group 217" clip-path="url(#clip-path)">
            <g id="Bubble_Message" data-name="Bubble Message" transform="translate(-1.403 -1.402)">
                <path id="Path_19247" data-name="Path 19247" d="M20.5,17.327A5.881,5.881,0,0,0,19.05,9.516a6.072,6.072,0,0,0-9.782,4.722c0,5.014,5.615,7.121,8.57,5.484a.351.351,0,0,1,.363.007,5.908,5.908,0,0,0,2.983.641.162.162,0,0,0,.121-.278,2.814,2.814,0,0,1-.809-2.765Z" fill="#8e8e8e" />
                <path id="Path_19248" data-name="Path 19248" d="M18.768,8.469a8.9,8.9,0,0,0-8.456-6.033,8.837,8.837,0,0,0-8.909,8.8,7.086,7.086,0,0,0,1.3,4.54,4.133,4.133,0,0,1-1.19,4.07.242.242,0,0,0,.178.41,8.721,8.721,0,0,0,4.39-.944.515.515,0,0,1,.535-.011,7.006,7.006,0,0,0,3.7.734,9.086,9.086,0,0,0,1.438-.114,6.668,6.668,0,0,1-3.194-5.68A6.784,6.784,0,0,1,18.768,8.469Z" fill="#8e8e8e" />
            </g>
        </g>
    </svg>
    )
}