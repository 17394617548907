import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import { ADD_JOB_URL, GET_JOB_URL, UPD_JOB_URL } from "./constants";
import store from "../../store";
function* getJobs(action) {
  let response;
  try {
    yield put({ type: "GENERAL-LOADING-JOB" });
    response = yield AxiosInstance.get(process.env.REACT_APP_BASE_URL +

      GET_JOB_URL
    );
    yield put({ type: "GET-JOB-REDUCER", payload: response.data.data });
    let res = yield AxiosInstance.get(process.env.REACT_APP_BASE_URL +

      "/api/v1/work_types"
    );
    yield put({ type: "GET-WORK", payload: res.data.data });
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "GENERAL-DONE-JOB" });
  } catch (e) {
    yield put({ type: "GENERAL-DONE-JOB" });
    // if(e.message){
    //     toast.error(e.message)
    // }
    // else
    // toast.error("failed! please check conection")
    toast.error("failed! BACK END ERROR");
  }
}

function* watchgetJobs() {
  yield takeEvery("GET-JOB", getJobs);
}
function* AddJobs(action) {
  let responses;
  try {
    yield put({ type: "GENERAL-DONE-JOB" });
    yield put({ type: "request-running-job", payload: action.payload });
    yield AxiosInstance.post(process.env.REACT_APP_BASE_URL +

      ADD_JOB_URL,
      JSON.stringify(action.payload)
    );

    yield put({ type: "request-running-job-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-job-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-JOB" });
    responses = yield AxiosInstance.get(process.env.REACT_APP_BASE_URL +

      GET_JOB_URL
    );
    yield put({ type: "GET-JOB-REDUCER", payload: responses.data.data });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-job",
      payload: { data: action.payload, message: e.message },
    });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddJobs() {
  yield takeEvery("ADD-JOB", AddJobs);
}
function* UpdateJobs(action) {
  let responses;
  try {
    yield put({ type: "GENERAL-DONE-JOB" });
    yield put({ type: "request-running-job", payload: action.payload });
    yield AxiosInstance.post(process.env.REACT_APP_BASE_URL +

      UPD_JOB_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-job-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-job-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-JOB" });
    responses = yield AxiosInstance.get(process.env.REACT_APP_BASE_URL +

      GET_JOB_URL
    );
    yield put({ type: "GET-JOB-REDUCER", payload: responses.data.data });

    yield put({ type: "GENERAL-DONE-JOB" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-job",
      payload: { data: action.payload, message: e.message },
    });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdJobs() {
  yield takeEvery("UPD-JOB", UpdateJobs);
}

export function* JobsSaga() {
  yield all([fork(watchgetJobs), fork(watchUpdJobs), fork(watchAddJobs)]);
}
