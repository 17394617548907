import { all, fork, takeEvery, put } from "@redux-saga/core/effects"
import axios from "axios";

function* getBallets(action) {
    const AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 0,
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user"))?.access_token),
            current_role_id:
                localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    ? localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    : "-1",
            // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
            "Content-Type": "application/json",
        },
    });
    try {
        let ballets = yield AxiosInstance.post("/api/v1/pallets/my_pallets");
        yield put({ type: "GET_BALLETS_REDUCER", payload: ballets.data.data })
    } catch (e) {
    }
}
function* watchGetBallets() {
    yield takeEvery("GET_BALLETS_SAGA", getBallets);
}

function* getBalletsStatus(action) {
    const AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 0,
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user"))?.access_token),
            current_role_id:
                localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    ? localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    : "-1",
            // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
            "Content-Type": "application/json",
        },
    });
    try {
        let ballets = yield AxiosInstance.get("/api/v1/pallet_statuses/main_statuses");
        yield put({ type: "GET_BALLETS_STATUS_REDUCER", payload: ballets.data.data })
    } catch (e) {
    }
}
function* watchGetBalletsStatus() {
    yield takeEvery("GET_BALLETS_STATUS_SAGA", getBalletsStatus);
}

function* getBalletsCountries(action) {
    const AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 0,
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user"))?.access_token),
            current_role_id:
                localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    ? localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    : "-1",
            // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
            "Content-Type": "application/json",
        },
    });
    try {
        let ballets = yield AxiosInstance.get("/api/v1/regions/get_countries_with_cities");
        yield put({ type: "GET_BALLETS_COUNTRIES_REDUCER", payload: ballets.data.data })
    } catch (e) {
    }
}
function* watchGetBalletsCountries() {
    yield takeEvery("GET_BALLETS_COUNTRIES_SAGA", getBalletsCountries);
}

function* assignToMe(action) {
    const AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 0,
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user"))?.access_token),
            current_role_id:
                localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    ? localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    : "-1",
            // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
            "Content-Type": "application/json",
        },
    });
    try {
        let response = yield AxiosInstance.post("/api/v1/pallets/assign_to_user", JSON.stringify(action.payload));
        yield put({ type: "GET_BALLET_REDUCER", payload: response.data.data })
    } catch (e) {
    }
}
function* watchAssignToMe() {
    yield takeEvery("notifi_ballet_to_user", assignToMe);
}

function* ChangeStatus(action) {
    const AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 0,
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user"))?.access_token),
            current_role_id:
                localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    ? localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    : "-1",
            // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
            "Content-Type": "application/json",
        },
    });
    try {
        let response = yield AxiosInstance.post("/api/v1/pallets/change_status", JSON.stringify({
            ...action.payload
        }));
        yield put({ type: "GET_BALLET_REDUCER", payload: response.data.data })
        yield put({ type: "PICKUP_PALLET_COMPLETE_REDUCER" })
    } catch (e) {
    }
}
function* watchChangeStatus() {
    yield takeEvery("PICKUP_PALLET_COMPLETE", ChangeStatus);
}

function* ReadyForNext(action) {
    const AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 0,
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user"))?.access_token),
            current_role_id:
                localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    ? localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role_id
                    : "-1",
            // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
            "Content-Type": "application/json",
        },
    });
    try {
        let response = yield AxiosInstance.post("/api/v1/pallets/ready_for_next_status", JSON.stringify({
            ...action.payload
        }));
        yield put({ type: "GET_BALLET_REDUCER", payload: response.data.data })
    } catch (e) {
    }
}
function* watchReadyForNext() {
    yield takeEvery("START_PICKUP_PALLET_DISCHARGE", ReadyForNext);
}

export function* ShippingSaga() {
    yield all([
        fork(watchGetBallets),
        fork(watchGetBalletsStatus),
        fork(watchGetBalletsCountries),
        fork(watchAssignToMe),
        fork(watchChangeStatus),
        fork(watchReadyForNext)
    ]);
}