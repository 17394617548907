export default function Order() {
    return (<svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_317" data-name="Mask Group 317">
            <g id="Group_10700" data-name="Group 10700" transform="translate(-91.505 -462.651)">
                <g id="Group_10699" data-name="Group 10699" transform="translate(-25.501 5.25)">
                    <g id="Mask_Group_315" data-name="Mask Group 315">
                        <path id="Path_21342" data-name="Path 21342" d="M6.273,2.309c-1.567-.969-3.179.566-3.179.566L1.388,4.582s-.922.8.448,2.168S4,7.2,4,7.2L5.708,5.488s1.535-1.611.566-3.178ZM5.742,3.364A.37.37,0,1,1,5.852,3.1a.371.371,0,0,1-.109.263Z" transform="translate(120.668 458.932)" fill="#f26666" />
                        <path id="Path_21343" data-name="Path 21343" d="M6.318,2.232a2.221,2.221,0,0,0-1.357-.324,3.04,3.04,0,0,0-1.149.359,3.647,3.647,0,0,0-.874.637l-1.2,1.2c-.134.134-.266.269-.4.4a1.134,1.134,0,0,0-.165.2A1.17,1.17,0,0,0,1,5.389a1.967,1.967,0,0,0,.48,1.1c.475.594,1.3,1.31,2.125,1.05a1.109,1.109,0,0,0,.436-.252c.064-.061.125-.125.187-.188L5.464,5.859a5.025,5.025,0,0,0,.605-.684,2.882,2.882,0,0,0,.56-2.237,2.256,2.256,0,0,0-.279-.675c-.06-.1-.216-.008-.155.091a2.063,2.063,0,0,1,.3,1.305,2.87,2.87,0,0,1-.358,1.067,3.445,3.445,0,0,1-.584.8L4.378,6.69l-.417.417-.038.038-.045.042-.032.026c.018-.014-.021.015-.029.02a.975.975,0,0,1-.579.174,1.733,1.733,0,0,1-1.023-.439C1.67,6.53.96,5.757,1.233,4.989a1,1,0,0,1,.12-.23l.019-.026L1.4,4.7q.02-.023.041-.044l.2-.2L2.85,3.25a4.928,4.928,0,0,1,.626-.563,2.738,2.738,0,0,1,2.1-.566,2.1,2.1,0,0,1,.651.266c.1.061.19-.1.091-.155Z" transform="translate(120.668 458.932)" fill="#3c3c59" />
                        <path id="Path_21344" data-name="Path 21344" d="M5.679,3.3l-.02.018-.009.008h0a.418.418,0,0,1-.048.029l-.013.006c.011,0,0,0-.006,0H5.574a.369.369,0,0,1-.053.014H5.463c-.006,0-.035,0-.012,0H5.4l-.024-.008L5.37,3.36c-.011,0,.014.007,0,0a.45.45,0,0,1-.045-.026l-.019-.013c.015.011-.008-.007-.011-.011a.43.43,0,0,1-.037-.039c.011.014,0,0-.006-.009a.1.1,0,0,1-.016-.026l-.012-.023c-.008-.016,0,.011,0-.005a.418.418,0,0,1-.015-.053.048.048,0,0,0,0-.025V3.124a.476.476,0,0,1,0-.054h0V3.064a.046.046,0,0,1,.005-.025.4.4,0,0,1,.016-.048c-.007.017,0,0,0-.008l.013-.023.014-.022,0-.014h0A.375.375,0,0,1,5.3,2.89l.012-.01h0l.025-.017.022-.014.014-.007h0l.017-.006.028-.008.021,0h.091a.368.368,0,0,1,.053.015l.011,0s-.014-.007,0,0l.023.011.023.014.009.006s.018.014.009.006h0l.011.011.02.019.018.02h0l.011.016A.462.462,0,0,1,5.735,3c-.008-.016,0,0,0,.009a.13.13,0,0,0,.008.028c0,.009,0,.019.006.029s0,.019,0,.006v.006a.368.368,0,0,1,0,.058s0,.019,0,.006v.006a.056.056,0,0,1-.006.029.105.105,0,0,0-.008.028l-.005.014c0,.012.007-.013,0,0a.542.542,0,0,1-.029.052l-.007.009c-.014.02.008-.007,0,0L5.679,3.3a.09.09,0,1,0,.128.128A.467.467,0,0,0,5.9,2.9a.457.457,0,1,0-.085.526A.09.09,0,0,0,5.683,3.3Z" transform="translate(120.668 458.932)" fill="#3c3c59" />
                        <path id="Path_21345" data-name="Path 21345" d="M6.4,2.435A3.044,3.044,0,0,0,6.648.9C6.613.6,6.5.15,6.144.094s-.617.321-.76.588a3.059,3.059,0,0,0-.345,1.47c.006.344.079.939.5,1.031.113.025.161-.15.048-.173a.38.38,0,0,1-.242-.23,1.363,1.363,0,0,1-.116-.5A2.917,2.917,0,0,1,5.449.955,1.761,1.761,0,0,1,5.735.481.557.557,0,0,1,6.047.272c.278-.039.375.359.412.558a2.775,2.775,0,0,1-.215,1.514c-.046.105.11.2.155.091Z" transform="translate(120.668 458.932)" fill="#3c3c59" />
                    </g>
                </g>
                <path id="Path_21394" data-name="Path 21394" d="M14.976,9.707,13.2,6.161a.215.215,0,0,0-.08-.1.225.225,0,0,0-.133-.044H2.015a.233.233,0,0,0-.128.041.225.225,0,0,0-.085.1L.025,9.707a.232.232,0,0,0,.1.311.225.225,0,0,0,.1.025.23.23,0,0,0,.207-.128l1.344-2.68L1.8,11.519A1.007,1.007,0,0,0,2.8,12.524h9.425a1.007,1.007,0,0,0,1.005-1.005l-.013-4.288,1.344,2.683a.23.23,0,0,0,.207.128.225.225,0,0,0,.1-.025.232.232,0,0,0,.1-.311Zm-3.215.884a.464.464,0,0,1-.464.464H9.253a.464.464,0,0,1-.464-.464V9.171a.464.464,0,0,1,.464-.464H11.3a.464.464,0,0,1,.464.464Z" transform="translate(91.505 463.504)" fill="#5d5d5d" />
            </g>
        </g>
    </svg>
    )
}