import { FormattedMessage } from "react-intl"

export const trans = ({ id, defaultMessage, values = {} }) => {
    return (<FormattedMessage id={id} values={{ ...values }} defaultMessage={defaultMessage} />)
}
//************************************//
export const _getAppLang = (lang) => {
    return localStorage.getItem('APP_LANG')
}
//************************************//
export const _setAppLang = (lang) => {
    localStorage.setItem('APP_LANG', lang)
}
//************************************//
export const _changeAppLang = (lang) => {
    localStorage.setItem('APP_LANG', lang)
    window.location.reload()
}
//************************************//s
export const showTrans = (data, lang) => {

    if (data && data.filter((a) => a.language_code === lang).length > 0) {
        return data.filter((a) => a.language_code === lang)[0].name
    }
    else {
        return "Not Entered"
    }
}
export const showTransRegions = (data, lang) => {
    if (data && data?.filter((a) => a.language_code === lang).length > 0) {
        return data?.filter((a) => a.language_code === lang)[0].region_name
    }
    else {
        return "Not Entered"
    }
}