import React from 'react'
import Package from "./svg/package"
import SmallPackage from './svg/small_package'
import Product from './svg/products'
import XButton from './svg/xButton'
import { useDispatch } from 'react-redux'

export default function MainStatuses(props) {
    const statuses = props.statuses
    const status = statuses[0]
    const dispatch = useDispatch()
    if (props.barcodeopened) {
        return (<div className="shipment_main_statuses">
            <div className={'status_barcode'} style={{ backgroundColor: status.color1 }}>
                <div className='status_barcode_first'>
                    <div className='status_icon'>
                        <Package icons_color={status.icons_color} icon_has_check={status.icon_has_check} />
                    </div>
                    <div className='status_info'>
                        <div className='status_info_name'>{status.translations[0].name}</div>
                        <div className='status_info_info'>
                            <div className='status_info_info_packages'>
                                <SmallPackage icons_color={status.icons_color} icon_has_check={status.icon_has_check} />
                                <div className='status_info_info_packages_num'>
                                    {status.pallets_count}
                                </div>
                            </div>
                            <div className='status_info_info_products'>
                                <Product icons_color={status.icons_color} />
                                <div className='status_info_info_products_num'>
                                    {status.orders_count}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='status_barcode_close' onClick={() => {
                    dispatch({ type: "SCAN_BARCODE_GLOBAL_CLOSE" })
                }}>
                    <XButton />
                </div>
            </div>
        </div>)
    } else {
        return (<div className="shipment_main_statuses">
            <div className={'status_normal'} style={{ backgroundColor: status.color1 }}>
                <div className='status_icon'>
                    <Package icons_color={status.icons_color} icon_has_check={status.icon_has_check} />
                </div>
                <div className='status_info'>
                    <div className='status_info_name'>{status.translations[0].name}</div>
                    <div className='status_info_info'>
                        <div className='status_info_info_packages'>
                            <SmallPackage icons_color={status.icons_color} icon_has_check={status.icon_has_check} />
                            <div className='status_info_info_packages_num'>
                                {status.pallets_count}
                            </div>
                        </div>
                        <div className='status_info_info_products'>
                            <Product icons_color={status.icons_color} />
                            <div className='status_info_info_products_num'>
                                {status.orders_count}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}