export default function SmallPackage(props) {
    let icons_color = props.icons_color
    let icon_has_check = props.icon_has_check
    if (icon_has_check) {
        return (<svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
            <g id="Mask_Group_306" data-name="Mask Group 306">
                <g id="prosse" transform="translate(0.114)">
                    <path id="Path_21382" data-name="Path 21382" d="M5.327,7.908A2.755,2.755,0,0,1,9.952,5.884q.02-1.412-.023-2.824a1.9,1.9,0,0,0-.993-1.617c-.314-.166-.64-.326-.97-.478A43.983,43.983,0,0,0,3.6,2.629a.868.868,0,0,0-.516.755c-.024.742-.031,1.508-.022,2.265a.1.1,0,0,1-.187.065Q2.632,5.4,2.4,5.1a.078.078,0,0,0-.1-.021l-.356.213a.1.1,0,0,1-.146-.093c0-.754.015-1.511.055-2.244a.9.9,0,0,1,.5-.742A34.233,34.233,0,0,1,6.738.445C6.426.323,6.115.211,5.812.1A1.943,1.943,0,0,0,4.55.1,22.53,22.53,0,0,0,1.425,1.443,1.9,1.9,0,0,0,.433,3.06Q.373,5,.433,6.938a1.9,1.9,0,0,0,.993,1.617A22.482,22.482,0,0,0,4.55,9.894a1.943,1.943,0,0,0,1.262,0L6.08,9.8a2.736,2.736,0,0,1-.753-1.892Z" transform="translate(-0.403 0.001)" fill="#5d5d5d" />
                    <path id="Path_21383" data-name="Path 21383" d="M22.2,20.509A2.091,2.091,0,1,0,24.288,22.6,2.091,2.091,0,0,0,22.2,20.509Zm1.193,1.745-1.257,1.32a.368.368,0,0,1-.265.114h0a.369.369,0,0,1-.265-.112l-.667-.687a.369.369,0,1,1,.529-.514l.4.412.992-1.042a.369.369,0,0,1,.534.508Z" transform="translate(-14.517 -14.692)" fill={icons_color} />
                </g>
            </g>
        </svg>)
    } else {
        return (<svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
            <g id="Mask_Group_304" data-name="Mask Group 304" clip-path="url(#clip-path)">
                <g id="Group_10637" data-name="Group 10637" transform="translate(-43.512 -41.188)">
                    <path id="Path_21382" data-name="Path 21382" d="M5.309,7.881A2.746,2.746,0,0,1,9.918,5.864q.019-1.407-.023-2.815a1.889,1.889,0,0,0-.989-1.612c-.313-.166-.638-.325-.966-.476A43.832,43.832,0,0,0,3.589,2.62a.865.865,0,0,0-.515.753c-.024.74-.031,1.5-.022,2.257a.1.1,0,0,1-.187.065q-.243-.311-.475-.616a.078.078,0,0,0-.1-.021l-.354.212a.1.1,0,0,1-.145-.093c0-.752.015-1.506.055-2.236a.9.9,0,0,1,.5-.74A34.115,34.115,0,0,1,6.715.444C6.4.322,6.094.21,5.792.1A1.936,1.936,0,0,0,4.534.1,22.453,22.453,0,0,0,1.42,1.438,1.89,1.89,0,0,0,.431,3.049q-.06,1.933,0,3.865A1.889,1.889,0,0,0,1.42,8.526,22.4,22.4,0,0,0,4.534,9.86a1.936,1.936,0,0,0,1.258,0l.267-.094a2.726,2.726,0,0,1-.75-1.885Z" transform="translate(43.224 41.189)" fill="#5d5d5d" />
                    <path id="Path_21383" data-name="Path 21383" d="M22.12,20.439A2.084,2.084,0,1,0,24.2,22.523a2.084,2.084,0,0,0-2.084-2.084Z" transform="translate(29.159 26.547)" fill={icons_color} stroke="#707070" stroke-width="0.08" />
                </g>
            </g>
        </svg>
        )
    }
}

