import axios from "axios"
const user = JSON.parse(localStorage.getItem("user"))

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 0,
  headers: {
    Authorization:
      "Bearer " +
      (localStorage.getItem("user") &&
        user.access_token),
    current_role_id:
      localStorage.getItem("user") &&
        user.role_id
        ? localStorage.getItem("user") &&
        user.role_id
        : "-1",
    "Content-Type": "application/json",
  },
});
export const AxiosInstanceUser = axios.create({
  baseURL:
    process.env.REACT_APP_BASE_URL,
  timeout: 0,
  headers: {
    Authorization:
      "Bearer " +
      (localStorage.getItem("user") &&
        user.access_token),
    current_role_id:
      localStorage.getItem("user") &&
        user.role_id
        ? localStorage.getItem("user") &&
        user.role_id
        : "-1",
    "Content-Type": "application/json",
  },
});

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // toast.error(error.message)
    // console.log(error)
    return Promise.reject(error);
  }
);
export const AxiosFileInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_FILE_URL,
  timeout: 0,
  headers: {
    Authorization:
      "Bearer " + (user && user.access_token),
    "Content-Type": "application/json",
  },
});

export const AxiosChatInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_FILE_URL,
  timeout: 0,
  headers: {
    Authorization:
      "Bearer " + (user && user.access_token),
    "Content-Type": "application/json",
  },
});

AxiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer " +
    (localStorage.getItem("user") &&
      user.access_token);
  config.headers.current_role_id =
    localStorage.getItem("user") &&
    user.role_id;
  return {
    ...config,
    headers: {
      ...config.headers,
      current_role_id:
        localStorage.getItem("user") &&
        user.role_id,
    },
  };
});

export default AxiosInstance;
