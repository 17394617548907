import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance from '../../helpers/axiosInstance';
import { toast } from "react-toastify"
import { ADD_LANGS_URL, ADD_SYS_LANG, GET_LANGS_URL, REM_SYS_LANG, UPD_LANGS_URL } from "./constants";
import store from "../store";
function* getAllLanguages(action) {
    let response;
    try {
        yield put({ type: "GENERAL-LOADING" })
        response = yield AxiosInstance.get(GET_LANGS_URL, {
            onDownloadProgress: progressEvent => {

                let percentCompleted = parseInt((progressEvent.loaded / progressEvent.total) * 100)
                store.dispatch({ type: "GENERAL-LOADING", percentCompleted })

            }
        })
        yield put({ type: "GET-LANG-REDUCER", payload: response.data.data })
        yield put({ type: "GENERAL-DONE" })
    } catch (e) {
        yield put({ type: "GENERAL-LOADING" })
        if (e.message) {
            toast.error(e.message)
        }
        else
            toast.error("failed! please check conection")
    }
}

function* watchGetAllLanguages() {
    yield takeEvery('GET-LANG', getAllLanguages)
}
function* AddLanguage(action) {
    let response, responses;
    try {

        yield put({ type: "GENERAL-DONE" })
        yield put({ type: "request-running", payload: action.payload })
        response = yield AxiosInstance.post(ADD_LANGS_URL, JSON.stringify(action.payload))
        if (action.payload.parent_language_code === null && response.data.data) {
            let data = action.payload
            data.translations = [...data.translations, { name: data.name_in_native_language, language_code: data.language_code }]
            yield AxiosInstance.post(UPD_LANGS_URL, JSON.stringify({ ...data, id: response.data.data.id }))
        }


        yield put({ type: "request-running-red", payload: action.payload })
        setTimeout(() => {
            store.dispatch({ type: "request-running-red", payload: null })
        }, 4000)
        yield put({ type: "GENERAL-DONE" })
        responses = yield AxiosInstance.get(GET_LANGS_URL)
        yield put({ type: "GET-LANG-REDUCER", payload: responses.data.data })
        // toast.success("Added Successfuly")
    } catch (e) {
        toast.error(response.data.message)
        yield put({ type: "GENERAL-ERR", payload: { data: action.payload, message: response.data && response.data.message ? response.data.message : e.message } })
        // if(e.message){
        //     toast.error(e.message)
        // }
        // else



    }
}

function* watchAddLanguages() {
    yield takeEvery('ADD-LANG', AddLanguage)
}
function* UpdateLanguages(action) {
    let responses
    try {

        yield put({ type: "GENERAL-DONE" })
        yield put({ type: "request-running", payload: action.payload })
        yield AxiosInstance.post(UPD_LANGS_URL, JSON.stringify(action.payload))
        yield put({ type: "request-running-red", payload: action.payload })
        setTimeout(() => {
            store.dispatch({ type: "request-running-red", payload: null })
        }, 4000)

        yield put({ type: "GENERAL-DONE" })
        responses = yield AxiosInstance.get(GET_LANGS_URL)
        yield put({ type: "GET-LANG-REDUCER", payload: responses.data.data })
        // toast.success("Updated Successfuly")
        yield put({ type: "GENERAL-DONE" })
    } catch (e) {
        yield put({ type: "GENERAL-ERR", payload: { data: action.payload, message: e.message } })
        if (e.message) {
            toast.error(e.message)
        }
        else
            toast.error("failed! please check conection")

    }
}

function* watchUpdLanguages() {
    yield takeEvery('UPD-LANG', UpdateLanguages)
}
function* AddLanguageSystem(action) {
    try {
        yield put({ type: "MODE-LANG", payload: "read" })
        yield put({ type: "GENERAL-DONE" })
        yield put({ type: "request-running", payload: action.payload.child })
        yield put({ type: "SET-LANGUAGE-R", payload: action.payload.value })
        if (parseInt(action.payload.value) === 0)
            yield AxiosInstance.post(ADD_SYS_LANG, JSON.stringify({ language_code: action.payload.child.language_code }))
        else
            if (parseInt(action.payload.value) === 1)
                yield AxiosInstance.post(REM_SYS_LANG, JSON.stringify({ language_code: action.payload.child.language_code }))
        yield put({ type: "request-running-red", payload: action.payload.child, message: parseInt(action.value) === 0 ? "Activated" : "Deactivated" })
        setTimeout(() => {
            store.dispatch({ type: "request-running-red", payload: null, message: action.value === 1 ? "Activated" : "Deactivated" })
        }, 2000)
        yield put({ type: "GENERAL-DONE" })
        yield put({ type: "SET-LANG-RED", payload: { child: action.payload.child, value: action.payload.value === 0 ? 1 : 0, message: parseInt(action.value) === 0 ? "Activated" : "Deactivated" } })
        // toast.success("Added Successfuly")
    } catch (e) {
        yield put({ type: "GENERAL-ERR", payload: { data: action.payload, message: e.message } })
        if (e.message) {
            toast.error(e.message)
        }
        else
            toast.error("failed! please check conection")
    }
}
function* watchAddLanguageSystem() {
    yield takeEvery('SET-LANGUAGE', AddLanguageSystem)
}
function* setFilePath(action) {
    try {
        yield put({ type: "GENERAL-LOADING" })
        yield AxiosInstance.post("/api/v1/languages/set_file_path/" + action.payload.lang, JSON.stringify({ file_path: action.payload.file }))
        yield put({ type: "GENERAL-DONE" })

    }
    catch (e) {
        yield put({ type: "GENERAL-DONE" })
    }
}
function* watchSetFile() {
    yield takeEvery("SET_FILE", setFilePath)
}
function* getLanguagesFiles() {
    yield put({ type: "GENERAL-LOADING" })
    yield AxiosInstance.get(GET_LANGS_URL, {
        onDownloadProgress: progressEvent => {

            let percentCompleted = parseInt((progressEvent.loaded / progressEvent.total) * 100)
            store.dispatch({ type: "GENERAL-LOADING", percentCompleted })

        }
    })
}
function* watchGetLanguagesFiles() {
    yield takeEvery("GET_FILE", getLanguagesFiles)
}
export function* languagesSaga() {
    yield all([
        fork(watchGetAllLanguages),
        fork(watchUpdLanguages),
        fork(watchAddLanguages),
        fork(watchAddLanguageSystem),
        fork(watchGetLanguagesFiles),
        fork(watchSetFile)
    ])
}