import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import "./ship.css"
import "./ship2.css"
import "./bulks.css"
import MainStatuses from './main_statuses'
import BulkList from './bulks_list'
import { useEffect } from 'react'
import PickUp from './pickup'

export default function Shipments(props) {
    const dispatch = useDispatch()
    const [lang, setLang] = useState(null)
    useEffect(() => {
        dispatch({ type: "GET_BALLETS_SAGA" })
        dispatch({ type: "GET_BALLETS_STATUS_SAGA" })
        dispatch({ type: "GET_BALLETS_COUNTRIES_SAGA" })
    }, [lang])
    const [activeStatus, setActiveStatus] = useState(null)
    const [currentBulk, setCurrentBulk] = useState(null)
    const countries = useSelector(state => state.shipping.countries)
    const bulks = useSelector(state => state.shipping.ballets)
    const mainStatuses = useSelector(state => state.shipping.mainStatuses)
    const pickup_id = useSelector(state => state.shipping.pickup_id)
    const orders_approved = useSelector(state => state.shipping.orders_approved)

    return (<div className={`languages-page ${props.active && "Active-page"}`}>
        {(mainStatuses.length > 0) && <MainStatuses statuses={mainStatuses} activeStatus={activeStatus} setActiveStatus={(e) => setActiveStatus(e)} />}
        {pickup_id && !orders_approved ? <div className='bulk_list_parent'>
            <div className='child_div'>
                <PickUp pallet={bulks.filter(one => one.id === pickup_id)[0]} countries={countries} mainStatuses={mainStatuses} />
            </div>
        </div> :
            <BulkList currentBulk={currentBulk} setCurrentBulk={(e) => setCurrentBulk(e)} data={bulks} countries={countries} mainStatuses={mainStatuses} />
        }
    </div>)
}