import React from 'react'
import Shipment from '../Routes/shipments/index'

function Sidebar() {
  return (
    <div className='main-sidebar' id="sidebar">
      <Shipment active />
    </div>
  )
}

export default Sidebar