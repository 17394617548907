import Bulk from "./bulk"
import BulkApproved from "./bulk_approved"
import { useSelector } from "react-redux"

export default function BulkList(props) {
    const list = props.data
    const pickup_id = useSelector(state => state.shipping.pickup_id)
    const orders_approved = useSelector(state => state.shipping.orders_approved)
    return (<div className={`${props.expanded ? 'bulk_list_parent_expanded' : 'bulk_list_parent'}`}>
        {list.map((bulk, index) => {
            if ((pickup_id === bulk.id) && orders_approved) {
                return (<div key={index} className="child_div">
                    <BulkApproved
                        filters={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]?.sub_statuses}
                        currentBulk={props.currentBulk}
                        setCurrentBulk={(e) => props.setCurrentBulk(e)}
                        bulk={bulk}
                        status={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]}
                        filter={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]?.sub_statuses?.filter(filter => filter.id === bulk.pallet_status_id)[0]}
                        country={props.countries.filter(country => country.id === bulk.country_id)[0]}
                        city={props.countries.filter(country => country.id === bulk.country_id)[0]?.sub_regions.filter(city => city.id === bulk.city_id)[0]}
                    />
                </div>)
            } else {
                return (<div key={index} className="child_div">
                    <Bulk
                        filters={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]?.sub_statuses}
                        currentBulk={props.currentBulk}
                        setCurrentBulk={(e) => props.setCurrentBulk(e)}
                        bulk={bulk}
                        status={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]}
                        filter={props.mainStatuses.filter(status => status.id === bulk.main_status_id)[0]?.sub_statuses?.filter(filter => filter.id === bulk.pallet_status_id)[0]}
                        country={props.countries.filter(country => country.id === bulk.country_id)[0]}
                        city={props.countries.filter(country => country.id === bulk.country_id)[0]?.sub_regions.filter(city => city.id === bulk.city_id)[0]}
                    />
                </div>)
            }
        })}
    </div>)
}