const initialState = {
    ballets: [],
    mainStatuses: [],
    countries: [],
    barscan: false,
    pickup_id: null,
    pickup_approve: false,
    orders_to_approve: [],
    orders_approved: false
};

export const shppingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BALLETS_REDUCER": return {
            ...state,
            ballets: action.payload
        };
        case "GET_BALLET_REDUCER": return {
            ...state,
            ballets: state.ballets.map((ballet) => {
                if (ballet.id === action.payload.id) {
                    return action.payload
                } else {
                    return ballet
                }
            })
        }
        case "GET_BALLETS_STATUS_REDUCER": return {
            ...state,
            mainStatuses: action.payload
        };
        case "GET_BALLETS_COUNTRIES_REDUCER": return {
            ...state,
            countries: action.payload
        };
        case "SCAN_BARCODE_GLOBAL": return {
            ...state,
            barscan: true
        };
        case "SCAN_BARCODE_GLOBAL_CLOSE": return {
            ...state,
            barscan: false
        };
        case "START_PICKUP_PALLET": return {
            ...state,
            pickup_id: action.payload.pallet_id
        };
        case "PICKUP_PALLET_APPROVE": {
            const orders = state.ballets.filter(bal => bal.id === state.pickup_id)[0].orders.map(order => {
                return ({
                    num: order.ShipmentNumber,
                    approved: false
                })
            })
            return {
                ...state,
                pickup_approve: true,
                orders_to_approve: orders
            }
        };
        case "PICKUP_ORDER_APPROVE": {
            const orders = state.orders_to_approve.map(order => {
                if (order.num === action.payload) {
                    return ({
                        ...order,
                        approved: true
                    })
                } else {
                    return ({ ...order })
                }
            })
            if (orders.filter(one => one.approved === false).length === 0) {
                return {
                    ...state,
                    orders_to_approve: orders,
                    orders_approved: true
                }
            } else {
                return {
                    ...state,
                    orders_to_approve: orders
                }
            }
        };
        case "PICKUP_PALLET_COMPLETE_REDUCER": return {
            ...state,
            barscan: false,
            pickup_id: null,
            pickup_approve: false,
            orders_to_approve: [],
            orders_approved: false
        }
        case "CLOSE_PICKUP": return {
            ...state,
            barscan: false,
            pickup_id: null,
            pickup_approve: false,
            orders_to_approve: [],
            orders_approved: false
        }
        default: return { ...state };
    }
}