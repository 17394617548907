import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import ability from './config/acl/ability'
import { AbilityContext } from './config/acl/Can'
// Import custom components
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import { IntlProviderWrapper } from './Internationalization'
import { getLanguagesFiles } from './redux/translate/actions';
const container = document.getElementById('root');
const root = createRoot(container);

let FinalLangs = getLanguagesFiles().catch(error => {
  return ({})
})

root.render(
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <IntlProviderWrapper additionalLangs={FinalLangs}>
        <App />
      </IntlProviderWrapper>
    </AbilityContext.Provider>
  </Provider>
);
serviceWorker.register()
