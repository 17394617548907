export default function SmallCountryIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
        <g id="Group_11044" data-name="Group 11044" transform="translate(-215 -388)">
            <g id="Mask_Group_94" data-name="Mask Group 94" transform="translate(215 389.739)" clip-path="url(#clip-path)">
                <g id="iraq" transform="translate(0.023 -1.716)">
                    <path id="Path_16025" data-name="Path 16025" d="M9.209,1.716H.746A.746.746,0,0,0,0,2.462V3.89H9.954V2.462A.746.746,0,0,0,9.209,1.716Z" fill="#ff4b55" />
                    <path id="Path_16026" data-name="Path 16026" d="M0,7.493a.746.746,0,0,0,.746.746H9.209a.746.746,0,0,0,.746-.746V6.064H0Z" fill="#464655" />
                    <rect id="Rectangle_4603" data-name="Rectangle 4603" width="9.954" height="2.174" transform="translate(0 3.89)" fill="#f5f5f5" />
                    <g id="Group_4684" data-name="Group 4684">
                        <path id="Path_16027" data-name="Path 16027" d="M5.99,4.54c-.018-.01-.04-.043-.033-.058s0-.014.033,0a.15.15,0,0,0,.152-.027l.038-.03.043.022a.157.157,0,0,0,.057.017.123.123,0,0,0,.059-.072c0-.023.017-.017.025.008a.119.119,0,0,1-.061.139.124.124,0,0,1-.077-.006c-.041-.016-.047-.016-.069,0a.154.154,0,0,1-.168.01" fill="#73af00" />
                        <path id="Path_16028" data-name="Path 16028" d="M6.161,4.359a.351.351,0,0,1-.041-.165c.008-.024.015-.029.037-.022.037.013.047.037.043.126-.007.066-.015.087-.037.062" fill="#73af00" />
                        <path id="Path_16029" data-name="Path 16029" d="M4.686,4.836c-.09.051-.225.06-.225.2-.2.007-.429.016-.639.012.057-.073.416-.405.565-.537a.083.083,0,0,0,.019-.093l0-.008a.073.073,0,0,0-.116-.021c-.149.143-.3.275-.32.3l-.28.282a.115.115,0,0,0-.033.082h0a.116.116,0,0,0,.116.115h.843C4.66,5.168,4.6,5,4.663,5c.043,0,.047.06.043.123a.087.087,0,0,1-.087.087c-.365,0-.744,0-1.1,0a.053.053,0,0,1-.053-.053V5.1a.068.068,0,0,0-.106-.056l0,0a.06.06,0,0,0-.027.07.2.2,0,0,1,.009.06.053.053,0,0,1-.052.053c-.118,0-.247,0-.283-.065a.077.077,0,0,0-.1-.038l-.005,0a.082.082,0,0,0-.036.113.421.421,0,0,1,.063.293.2.2,0,0,1-.083.087.041.041,0,0,0,.03.075.263.263,0,0,0,.206-.207h1.68a.112.112,0,0,0,.112-.112V5.013C4.849,4.9,4.732,4.909,4.686,4.836Z" fill="#73af00" />
                        <path id="Path_16030" data-name="Path 16030" d="M6.817,4.354a.7.7,0,0,0,.077.043.056.056,0,0,1,.024.08.082.082,0,0,0-.011.026v.723a.119.119,0,0,0,.1-.015.043.043,0,0,1,.068.025c.017.063.043.125.06.182a.056.056,0,0,1-.053.07H6.8a.043.043,0,0,1-.043-.043V4.389a.043.043,0,0,1,.062-.035Z" fill="#73af00" />
                        <path id="Path_16031" data-name="Path 16031" d="M6.473,4.53c0,.2,0,.415,0,.629a.05.05,0,0,1-.05.05h0a.05.05,0,0,1-.05-.05V4.652a.032.032,0,0,0-.053-.024l-.067.057a.062.062,0,0,0-.021.047c0,.083,0,.323,0,.427a.05.05,0,0,1-.05.05H6.174a.05.05,0,0,1-.05-.05c0-.07,0-.2,0-.3A.033.033,0,0,0,6.07,4.84a1.314,1.314,0,0,0-.121.111c0,.019.018,0,.027.015v.1c-.251-.047-.27.153-.261.368a.06.06,0,0,0,.06.058h.79a.053.053,0,0,0,.053-.053v-.98a.037.037,0,0,0-.063-.027L6.5,4.48a.068.068,0,0,0-.024.05Zm-.58.672c-.015-.043.071-.06.082-.02s-.062.05-.081.02Z" fill="#73af00" />
                        <path id="Path_16032" data-name="Path 16032" d="M3.291,5.568a.055.055,0,0,1,.054.054.054.054,0,0,1-.054.054.055.055,0,0,1-.056-.054.056.056,0,0,1,.056-.054" fill="#73af00" />
                        <path id="Path_16033" data-name="Path 16033" d="M5.083,4.376a.518.518,0,0,0,.046.025.053.053,0,0,1,.023.077.087.087,0,0,0-.013.029v.722a.115.115,0,0,0,.1-.017.04.04,0,0,1,.063.022c.013.049.033.1.048.143a.087.087,0,0,1-.082.114H5.05a.065.065,0,0,1-.065-.065V4.432a.065.065,0,0,1,.1-.056Z" fill="#73af00" />
                    </g>
                </g>
            </g>
            <rect id="Rectangle_4644" data-name="Rectangle 4644" width="10" height="10" transform="translate(215 388)" fill="none" />
        </g>
    </svg>
    )
}