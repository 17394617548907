export default function BulkIcon(props) {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_309" data-name="Mask Group 309" clip-path="url(#clip-path)">
            <g id="Group_10637" data-name="Group 10637" transform="translate(0.164 0)">
                <path id="Path_21382" data-name="Path 21382" d="M7.852,11.319a3.944,3.944,0,0,1,6.62-2.9Q14.5,6.4,14.438,4.379a2.713,2.713,0,0,0-1.421-2.315c-.45-.238-.917-.467-1.388-.684A62.957,62.957,0,0,0,5.382,3.763a1.242,1.242,0,0,0-.739,1.081C4.608,5.907,4.6,7,4.612,8.087a.149.149,0,0,1-.268.093Q3.994,7.732,3.66,7.3a.112.112,0,0,0-.148-.03L3,7.569a.142.142,0,0,1-.209-.134c-.006-1.08.021-2.163.079-3.212a1.286,1.286,0,0,1,.713-1.062A49,49,0,0,1,9.871.637C9.424.462,8.979.3,8.545.149a2.781,2.781,0,0,0-1.807,0A32.25,32.25,0,0,0,2.266,2.065,2.715,2.715,0,0,0,.846,4.379Q.76,7.155.846,9.931a2.714,2.714,0,0,0,1.421,2.315,32.18,32.18,0,0,0,4.473,1.915,2.781,2.781,0,0,0,1.807,0l.383-.135a3.916,3.916,0,0,1-1.077-2.708Z" transform="translate(-0.803 0.002)" fill="#5d5d5d" />
                <path id="Path_21383" data-name="Path 21383" d="M43.066,40.878a2.993,2.993,0,1,0,2.994,2.993,2.993,2.993,0,0,0-2.994-2.993Z" transform="translate(-32.074 -32.55)" fill={props.color} />
            </g>
        </g>
    </svg>
    )
}