import React from 'react'
import avat from "../../assets/svg/avat.png"

function Avatar() {
  return (
    <div className='avatar-component'>
      <div className='avatar'>
        <img alt="avatar" src={localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).avatar && JSON.parse(localStorage.getItem("user")).avatar.length > 0 ? process.env.REACT_APP_BASE_FILE_URL + JSON.parse(localStorage.getItem("user")).avatar : avat} />
      </div>
      <div className='user-name'>
        {localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).username}
      </div>
    </div>
  )
}

export default Avatar