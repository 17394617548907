// ** React Imports
import { createContext } from 'react'
/* ************************************************ */
import { IntlProvider } from 'react-intl'
import flatten from 'flat'
import _ from "lodash"
/* ************************************************ */
import { _changeAppLang, _setAppLang } from './Utils.js'
import { genLang } from './assets/translation/index'

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children, additionalLangs }) => {

    const en = additionalLangs.en
    const tr = additionalLangs.tr
    const ar = additionalLangs.ar

    delete additionalLangs.en
    delete additionalLangs.tr
    delete additionalLangs.ar

    // ** Menu msg obj
    const menuMessages = {
        en: { ...genLang.en, ...en },
        tr: { ...genLang.tr, ...tr },
        ar: { ...genLang.ar, ...ar },
        ...additionalLangs
    }
    // ** States
    const localStorageLang = localStorage.getItem('APP_LANG')
    const lang = localStorageLang ? localStorageLang : 'en'
    const messages = flatten(menuMessages[lang])
    _setAppLang(lang)

    // ** Switches Language
    const switchLanguage = lang => {
        _changeAppLang(lang)
    }

    // ** Switches Language
    const plainText = key => {
        return _.get(menuMessages, `${lang}.${key}`) ?? key
    }

    return (
        <Context.Provider value={{ lang, switchLanguage, plainText }}>
            <IntlProvider key={lang} locale={lang} messages={messages} defaultLocale='en'>
                {children}
            </IntlProvider>
        </Context.Provider>
    )
}

export { IntlProviderWrapper, Context as IntlContext }