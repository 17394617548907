const initialState = {
  view: "Departments",
  employee: [],
  statues: [],
  regions: [],
  departments: [],
  depts: [],
  pageFollower: ["Employees"],
  newDepartment: {
    department_id: null,
    department: [],
    dept: "",
    full_name: "",
    avatar: "",
    id_photo_path: "",
    passport_number: "",
    passport_photo_path: "",
    passport_region_id: "",
    passport_region_issued_from: "",
    job_code: "",
    id_no: "",
    id_issued_from: "",
    id_no_region_id: "",
    driving_license_no: "",
    driving_license_photo_path: "",
    driving_license_issued_from: "",
    driving_license_region_id: "",
    citizen_country_id: "",
    residence: "",
    city_id: "",
    birthdate: "",
    birth_city_id: "",
    full_address: "",
    contact_infos: [
      {
        country_id: null,
        phone_number: "",
        contact_name: "",
        contact_method: "",
        contact_time: "",
        is_main: true,
        is_close_person: false,
        is_reference_by: false,
      },
      {
        country_id: null,
        phone_number: "",
        contact_name: "",
        contact_method: "",
        contact_time: "",
        is_main: true,
        is_close_person: false,
        is_reference_by: false,
      },
      {
        country_id: null,
        phone_number: "",
        contact_name: "",
        contact_method: "",
        contact_time: "",
        is_main: true,
        is_close_person: true,
        is_reference_by: false,
      },
      {
        country_id: null,
        phone_number: "",
        contact_name: "",
        contact_method: "",
        contact_time: "",
        is_main: true,
        is_close_person: false,
        is_reference_by: true,
      },
    ],
  },
  mode: "read",
  open: false,
  modalData: { name: "", icon: null, code: "" },
  loading: false,
  Active_Ids: [],
  request_running: false,
  request_data: null,
  emp_loading: false,

  message: "",
  error: false,
  img_upload: { type: "", status: false },
  async_loading: false,
  reg_loading: false,

};

const getEmployeStatus = (statues, child) => {

  if (statues.filter((s) => s.id === child.user_status_id)[0]?.slug === "online") {
    return ("in")
  }
  if (statues.filter((s) => s.id === child.user_status_id)[0]?.slug === "in_break") {
    return ("br")
  }
  if (statues.filter((s) => s.id === child.user_status_id)[0]?.slug === "offline" || !child.user_status_id) {
    return ("out")
  }

};



const StatusFilter = (statues, da) => {
  let newEmp = da.map((e) => {
    return ({ ...e, status: getEmployeStatus(statues, e) })
  })

  return (newEmp)
}
export const EmployeeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET-EMP-STATUES-REDUCER": {
      return ({
        ...state,
        statues: payload
      })
    }
    case "EDIT-EMP": {
      let emp = state.employee.map((s) => {
        if (s.id === payload.id) {
          return ({ ...s, work_country_id: payload.work_country_id })
        }
        else {
          return (s)
        }
      })
      return ({
        ...state,
        employee: emp
      })
    }
    case "GET-EMP": {
      return ({ ...state, emp_loading: true })
    }
    case "GET-EMP-DEPT-REDUCER": {
      return ({
        ...state,
        depts: payload
      })
    }
    case "NEW-EMP-REG": {
      let a = state.newDepartment;
      Object.assign(a, payload);
      return { ...state, newDepartment: { ...a } };
    }
    case "ASYNC_LOAD_REG": {
      return {
        ...state,
        reg_loading: true,
      };
    }
    case "ASYNC_DONE_REG": {
      return {
        ...state,
        regions: payload,
        reg_loading: false,
      };
    }
    case "ASYNC_LOAD": {
      return {
        ...state,
        async_loading: true,
      };
    }
    case "ASYNC_DONE": {
      let arr = payload.map((a) => {
        return ({
          id: a.id,
          name: a.deep_parent_department_name
            ? `${a.deep_parent_department_name.name} | ${a.translations.filter((sd) => sd.language_code === "en")[0].name
            }`
            : a.translations.filter((sd) => sd.language_code === "en")[0].name,
          code: a.deep_parent_department_name
            ? `${a.deep_parent_department_name.short_name?.toUpperCase()} | ${a.translations
              .filter((sd) => sd.language_code === "en")[0]
              .short_name?.toUpperCase()}`
            : a.translations
              .filter((sd) => sd.language_code === "en")[0]
              .short_name?.toUpperCase(),
        });
      });
      return {
        ...state,
        departments: arr,
        async_loading: false,
      };
    }
    case "IMG-UPLOAD-EMP": {
      return {
        ...state,
        img_upload: { ...payload },
      };
    }
    case "request-running-EMP": {
      return {
        ...state,
        request_running: true,
        request_data: payload,
      };
    }
    case "GENERAL-ERR-EMP": {
      return {
        ...state,
        request_running: false,
        request_data: payload.data,
        error: true,
        message: payload.message,
      };
    }
    case "request-running-EMP-red": {
      return {
        ...state,
        request_running: false,
        request_data: payload,
        error: false,
        message: "",
      };
    }
    case "GENERAL-LOADING-EMP": {
      return {
        ...state,
        loading: true,
      };
    }
    case "VIEW-EMP": {
      return {
        ...state,
        view_language: payload,
      };
    }
    case "GENERAL-DONE-EMP": {
      return {
        ...state,
        loading: false,

      };
    }
    case "LEVEL-DONE-EMP": {
      return {
        ...state,
        loading: false,
      };
    }
    case "GET-EMP-REDUCER": {
      return {
        ...state,
        emp_loading: false,
        employee: StatusFilter(state.statues, payload),
        request_data: null,
        request_running: false,
        error: false,
        message: "",
      };
    }
    case "Active-add": {
      return {
        ...state,
        Active_Ids: payload,
      };
    }
    case "HRM-VIEW":
      return {
        ...state,
        view: payload,
        pageFollower: [payload],
      };
    case "NEW-EMP": {
      return {
        ...state,
        newDepartment: { ...payload },
      };
    }
    case "OPEN-EMP": {
      return {
        ...state,
        open: payload,
      };
    }
    case "MODE-EMP": {
      return {
        ...state,
        mode: payload,
      };
    }
    case "MOD-DATA-EMP": {
      return {
        ...state,
        modalData: { ...payload },
      };
    }

    default:
      return state;
  }
};
