export default function ButtonCart() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
        <g id="Mask_Group_321" data-name="Mask Group 321" transform="translate(0 0.222)">
            <g id="_07_Pickup" data-name="07 Pickup" transform="translate(0.045 0)">
                <path id="Path_21437" data-name="Path 21437" d="M14.69,12.757a1.169,1.169,0,0,0,.826-1.431l-1.762-6.6A1.171,1.171,0,0,0,12.321,3.9L5.724,5.67A1.173,1.173,0,0,0,4.9,7.1l1.8,6.707a1.057,1.057,0,0,0,1.293.746Z" transform="translate(1.061 0.034)" fill="#f7f7f7" />
                <path id="Path_21439" data-name="Path 21439" d="M21.289,16.29l-1.236-.714a2.369,2.369,0,0,0-1.8-.239l-6.36,1.705c-.114-.253.172-.236-.433-.708a8.775,8.775,0,0,0-1.988-1.179c-.72-.3-.594-.062-.892-.032L5.741,4.548A2.365,2.365,0,0,0,4.634,3.1L2.658,1.963a.528.528,0,1,0-.528.915L4.105,4.019a1.31,1.31,0,0,1,.621.8L7.556,15.4a3.277,3.277,0,1,0,4.607,2.658l6.36-1.705a1.308,1.308,0,0,1,1,.133l1.236.714c.253.146.781-.769.528-.915ZM9.481,20.548a2.231,2.231,0,1,1,1.36-1.038,2.231,2.231,0,0,1-1.36,1.038Z" transform="translate(-1.865 -1.893)" fill="#f7f7f7" />
            </g>
        </g>
    </svg>
    )
}