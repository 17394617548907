import React from 'react'
import BulkIcon from "./svg/bulk_icon"
import PackageCart from "./svg/package_cart"
import SmallCountryIcon from "./svg/small_iraq_icon"
import Product from "./svg/products"
import Kilogram from "./svg/kilogram"
import Vweight from "./svg/v_weight"
import Time from "./svg/time"
import ButtonCart from './svg/button_cart'
import { useDispatch } from 'react-redux'
import { useSelector } from "react-redux"
import Order from './svg/order'
// import Order from './svg/order'

export default function Bulk(props) {
    const bulk = props.bulk
    const status = props.status
    const filter = props.filter
    const country = props.country
    const city = props.city
    const currentBulk = props.currentBulk
    const filters = props.filters
    const pickup_id = useSelector(state => state.shipping.pickup_id)
    const orders_approved = useSelector(state => state.shipping.orders_approved)
    const pickup_approve = useSelector(state => state.shipping.pickup_approve)
    const dispatch = useDispatch()
    const setCurrentBulk = (e) => props.setCurrentBulk(e)
    const getTime = (t) => {
        let diff = parseFloat((new Date().getTime() - new Date(t).getTime() + (new Date().getTimezoneOffset() * 60 * 1000)) / (1000 * 3600)).toFixed(1)
        if (diff > 24) {
            return (`${(diff / 24).toFixed(1)} d`)
        } else {
            if (diff > 1) {
                return (`${diff} h`)
            } else {
                if (diff * 60 > 1) {
                    return (`${(diff * 60).toFixed(1)} m`)
                } else {
                    return (`${(diff * 3600).toFixed(1)} s`)
                }
            }
        }
    }
    return (<div className={`${currentBulk === bulk.id ? "bulk_element_with_status_selected" : "bulk_element_with_status"}`}>
        <div className="bulk_element" onClick={() => {
            if (currentBulk === bulk.id) {
                setCurrentBulk(null)
            } else {
                setCurrentBulk(bulk.id)
            }
        }} style={{ backgroundColor: pickup_approve ? "#F4F8FF" : "#F7F7F7", border: pickup_approve ? "0.5px solid #7C90FF" : "0.5px solid #DDDDDD" }}>
            <div className="bulk_element_name">
                <div className="bulk_element_name_icon">
                    <div className="bulk_icon">
                        <BulkIcon color={status ? status.icons_color : "#ffffff"} />
                    </div>
                    <div className="bulk_name">
                        {bulk.ShipmentNumber}
                    </div>
                </div>
                <div className="bulk_element_right_icon" style={{ color: filter ? filter.color2 : "#ffffff" }}>
                    {filter.translations[0]?.name}
                    <PackageCart color1={filter ? filter.color1 : "#ffffff"} color2={filter ? filter.color2 : "#ffffff"} />
                </div>
            </div>
            <div className="bulk_element_data">
                <div className="bulk_element_country_data">
                    <div className="bulk_element_country_icon">
                        <SmallCountryIcon />
                    </div>
                    <div className="bulk_element_country_name">
                        {country ? country.region_translations[0]?.region_name : "iraq"}, {city ? city.region_translations[0]?.region_name : "Erbil"}
                    </div>
                </div>
                <div className="bulk_element_detail_data">
                    <Product icons_color="#A7EDCB" />
                    <div className="products_order_count">{bulk.products_count}/{bulk.orders.length}</div>
                    <Kilogram />
                    <div className="bulk_element_weight">{bulk.weight}</div>
                    <Vweight />
                    <div className="bulk_element_weight">{bulk.v_weight}</div>
                    <Time color="#8E8E8E" />
                    <div className="bulk_element_time">
                        <div className="bulk_element_time_num"> 3&nbsp;</div>
                        <div className="bulk_element_time_unit" style={{ textTransform: "lowercase" }}> h </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={'bulk_orders_area_approved'} style={{ minHeight: "10px" }}>
            {bulk.orders?.map((order, index) => <div key={index} className={'bulk_orders_area_element_approved'}>
                <div className='bulk_order_icon_name'>
                    <div className='bulk_order_icon'> <Order /></div>
                    <div className='bulk_order_name'>{order.ShipmentNumber}</div>
                </div>
                <div className='bulk_order_details'>
                    <div>{order.products_count}</div>
                    <div>{order.weight}</div>
                    <div>{order.v_weight}</div>
                    <div className='bulk_order_time'>1&nbsp;d</div>
                </div>
            </div>)}
        </div>
        {currentBulk === bulk.id ? (<>
            <div className='ballet_action_button2'
                onClick={() => {
                    dispatch({
                        type: "PICKUP_PALLET_COMPLETE", payload: {
                            pallet_id: bulk.id
                        }
                    })
                }}
            >
                <ButtonCart />
                <div className='ballet_action_button_text'>
                    Pickup Completed
                </div>
            </div>
        </>) : <></>}
    </div>
    )
}