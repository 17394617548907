import { all } from 'redux-saga/effects'
import { AuthSaga } from './auth/saga'
import allCategoriesSaga from './categories/saga'
import { languagesSaga } from './LinkedLanguages/saga'
import { JobsSaga } from './HRM/JobTitle/saga'
import { DepartmentsSaga } from './HRM/departments/saga'
import { EmployeeSaga } from './HRM/employee/saga'
import { CountriesSaga } from './countries/countries/saga'
import { CompaniesSaga } from './countries/companies/saga'
import { OfficesSaga } from './countries/offices/saga'
import { LiscensesSaga } from './countries/Liscense/saga'
import TranslateSaga from './translate/saga'
import { NumsSaga } from './Currenciess/numcats/saga'
import { CurrSaga } from './Currenciess/currencies/saga'
import { ShippingSaga } from './shipment/saga'

export default function* rootSaga() {
    yield all([
        AuthSaga(),
        allCategoriesSaga(),
        languagesSaga(),
        JobsSaga(),
        DepartmentsSaga(),
        EmployeeSaga(),
        CountriesSaga(),
        CompaniesSaga(),
        OfficesSaga(),
        LiscensesSaga(),
        TranslateSaga(),
        NumsSaga(),
        CurrSaga(),
        ShippingSaga()
    ]);

}
