import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import {
  GET_DEPT_URL,
  UPD_DEPT_URL,
} from "./constants";
import store from "../../store";
import { OFFLINE } from 'redux-offline-status/lib/actions'
import { GET_LANGS_URL } from "../../LinkedLanguages/constants";
import axios from "axios";

function* getAllDepartments(action) {
  const AxiosInstanceUser = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user"))?.access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  try {
    if (action.bool) {
      yield put({ type: "GENERAL-LOADING-EMP" });
      yield put({ type: "GET-REG" });
      let langs = yield AxiosInstance.get(GET_LANGS_URL);
      yield put({ type: "GET-LANG-REDUCER", payload: langs.data.data });
      let resp = yield AxiosInstanceUser.post(
        "/api/v1/departments/index_with_employees",
        JSON.stringify(action.payload)
      );
      yield put({ type: "GET-EMP-DEPT-REDUCER", payload: resp.data.data });
      yield put({ type: "GENERAL-DONE" });
    }
    let resp = yield AxiosInstanceUser.post(
      "/api/v1/departments/index_with_employees",
      JSON.stringify(action.payload)
    );
    yield put({ type: "GET-EMP-DEPT-REDUCER", payload: resp.data.data });
    let responses = yield AxiosInstanceUser.post(
      GET_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "GET-EMP-REDUCER", payload: responses.data.data });
    let langs = yield AxiosInstanceUser.get(GET_LANGS_URL);
    yield put({ type: "GET-LANG-REDUCER", payload: langs.data.data });
    yield put({ type: "GENERAL-DONE" });

    yield put({ type: "GENERAL-DONE-EMP" });
  } catch (e) {
    store.dispatch({ type: OFFLINE });
    yield put({ type: "GENERAL-DONE-EMP" });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchGetAllDepartments() {
  yield takeEvery("GET-EMP", getAllDepartments);
}
function* AddDepartments(action) {
  const AxiosInstanceUser = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user"))?.access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  let responses;
  try {
    yield put({ type: "GENERAL-DONE-EMP" });
    yield put({ type: "request-running-EMP", payload: action.payload });
    yield AxiosInstanceUser.post(
      "/api/v1/employees/create",
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-EMP-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-EMP-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-EMP" });
    let pa = {
      filter: {
        full_name: "",
        department_ids: [],
        citizen_country_ids: [],
      },
    };
    responses = yield AxiosInstanceUser.post(GET_DEPT_URL, JSON.stringify(pa));
    yield put({ type: "GET-EMP-REDUCER", payload: responses.data.data });
    let resp = yield AxiosInstanceUser.post("/api/v1/departments/index_with_employees",
      JSON.stringify(pa)
    );
    yield put({ type: "GET-EMP-DEPT-REDUCER", payload: resp.data.data });
    yield put({ type: "GENERAL-DONE-EMP" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-EMP",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddDepartments() {
  yield takeEvery("ADD-EMP", AddDepartments);
}
function* UpdateDepartments(action) {
  const AxiosInstanceUser = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user"))?.access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  let responses;
  try {
    yield put({ type: "GENERAL-DONE-EMP" });
    yield put({ type: "request-running-EMP", payload: action.payload });
    yield AxiosInstanceUser.post(UPD_DEPT_URL + ("/" + action.payload.id),
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-EMP-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-EMP-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-EMP" });
    let pa = {
      filter: {
        full_name: "",
        department_ids: [],
        citizen_country_ids: [],
      },
    };
    responses = yield AxiosInstanceUser.post(GET_DEPT_URL, JSON.stringify(pa));
    yield put({ type: "GET-EMP-REDUCER", payload: responses.data.data });
    let resp = yield AxiosInstanceUser.post("/api/v1/departments/index_with_employees",
      JSON.stringify(pa)
    );
    yield put({ type: "GET-EMP-DEPT-REDUCER", payload: resp.data.data });
    yield put({ type: "GENERAL-DONE-EMP" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-EMP",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdDepartments() {
  yield takeEvery("UPD-EMP", UpdateDepartments);
}
function* getAsyncDept(action) {
  const AxiosInstanceUser = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user"))?.access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  try {
    yield put({ type: "ASYNC_LOAD" });
    yield AxiosInstanceUser.get(
      "/api/v1/departments/search/" + action.payload
    ).then((e) => {
      store.dispatch({
        type: "ASYNC_DONE",
        payload: e?.data?.data,
      });
    });
  } catch (e) {
    yield put({ type: "ASYNC_DONE", payload: [] });
  }
}
function* watchGetAsync() {
  yield takeEvery("ASYNC_DEPT", getAsyncDept);
}
function* getAsyncRegions(action) {
  try {
    if (action.payload.length !== 0) {
      yield put({ type: "ASYNC_LOAD_REG" });
      let response = yield AxiosInstance.get(
        process.env.REACT_APP_BASE_URL +
        "/api/v1/regions/filter/" +
        action.payload
      );

      yield put({
        type: "ASYNC_DONE_REG",
        payload: response.data.data.regions.data,
      });
    } else {
      yield put({ type: "ASYNC_DONE_REG", payload: [] });
    }
  } catch (e) {
    yield put({ type: "ASYNC_DONE_REG", payload: [] });
  }
}
function* watchGetAsyncReg() {
  yield takeEvery("ASYNC_REG", getAsyncRegions);
}
function* getAsyncLis(action) {
  try {
    if (action.payload.length !== 0) {
      yield put({ type: "ASYNC_LOAD_REG" });
      let response = yield AxiosInstance.get(
        process.env.REACT_APP_BASE_URL +
        "/api/v1/licenses/search/" +
        action.payload
      );

      yield put({
        type: "ASYNC_DONE_LIS",
        payload: response.data.data,
      });
    } else {
      yield put({ type: "ASYNC_DONE_LIS", payload: [] });
    }
  } catch (e) {
    yield put({ type: "ASYNC_DONE_LIS", payload: [] });
  }
}
function* watchGetAsyncLis() {
  yield takeEvery("ASYNC_LIS", getAsyncLis);
}

function* getAsyncRegionsCount(action) {
  try {
    if (action.payload.length !== 0) {
      yield put({ type: "ASYNC_LOAD_REG" });
      let response = yield AxiosInstance.get(
        process.env.REACT_APP_BASE_URL +
        "/api/v1/regions/country_filter/" +
        action.payload
      );

      yield put({
        type: "ASYNC_DONE_REG",
        payload: response.data.data.regions.data,
      });
    } else {
      yield put({ type: "ASYNC_DONE_REG", payload: [] });
    }
  } catch (e) {
    yield put({ type: "ASYNC_DONE_REG", payload: [] });
  }
}
function* watchGetAsyncRegCount() {
  yield takeEvery("ASYNC_REG_count", getAsyncRegionsCount);
}
function* getEmpData(action) {

  const AxiosInstanceUser = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user"))?.access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  try {
    let data = action.payload;
    let a = data.citizen_country_id;
    let b = data.work_office_region_id;
    let c = data.license_id;
    let d = data.department_id;
    if (d) {
      let res = yield AxiosInstanceUser.get("/api/v1/departments/show/" + d);
      yield put({
        type: "NEW-EMP-REG",
        payload: {
          department: res.data.data.translations.filter(
            (t) => t.language_code === "en"
          ),
        },
      });
    }
    if (c) {
      let res = yield AxiosInstance.get("/api/v1/licenses/show/" + c);
      yield put({
        type: "NEW-EMP-REG",
        payload: {
          liscense_name: `${res.data.data.name}`,
        },
      });
    }
    if (b) {
      let res = yield AxiosInstance.get("api/v1/regions/show/" + b);
      yield put({
        type: "NEW-EMP-REG",
        payload: {
          office_work_name: `${res.data.data.country.region_translations?.filter(
            (reg) => reg.language_code === "en"
          )[0].region_name
            } ${res.data.data.city.region_translations?.filter(
              (reg) => reg.language_code === "en"
            )[0].region_name
            }`,
        },
      });
    }
    let res = yield AxiosInstance.get("api/v1/regions/show/" + a);
    yield put({
      type: "NEW-EMP-REG",
      payload: {
        citizen_country_name: res.data.data.region_translations?.filter(
          (reg) => reg.language_code === "en"
        )[0].region_name,
      },
    });
  } catch (e) {
    console.error(e);
  }
}
function* watchGetEmp() {
  yield takeEvery("GET_EMP_DATA", getEmpData);
}
function* getAsyncWorkOffice(action) {
  try {
    if (action.payload.length !== 0) {
      let response = yield AxiosInstance.get(
        process.env.REACT_APP_BASE_URL +
        "/api/v1/regions/tree_filter/" +
        action.payload
      );
      console.log(response)
      yield put({
        type: "GET_WORKOFF_RED",
        payload: response.data.data.regions
      });
    } else {
      yield put({ type: "GET_WORKOFF_RED", payload: [] });
    }
  } catch (e) {
    console.log(e)
    yield put({ type: "GET_WORKOFF_RED", payload: [] });
  }
}
function* watchGetAsyncWorkOffice() {
  yield takeEvery("GET_WORKOFF", getAsyncWorkOffice);
}
function* getStatues(action) {
  const AxiosInstanceUser = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user"))?.access_token),
      current_role_id:
        localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          ? localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role_id
          : "-1",
      // Authorization:'Bearer '+"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiM2I4MzY1NzJkNDFmZDgzNTU5ZGU1NjJjNTNhNGY4NTllMzc2NjJkZDBkOWZjNjZmOTk2YzFjM2QyZWY3NWI1ZTFmNGMwZmU2ZGU3ZGYwYjAiLCJpYXQiOjE2Njk0NjQ5NjkuNDI0OTYyLCJuYmYiOjE2Njk0NjQ5NjkuNDI0OTY2LCJleHAiOjE2NzcyNDA5NjkuMjQ5ODU0LCJzdWIiOiIiLCJzY29wZXMiOlsiMSIsIjIiXX0.OAGhSqUNoaEQu5_8iLo_nQys7CahTV9gLnFPuMbvBFn5Tr8dv4rrQ0Pw9Gldec2qn-A6mU-TBEitJmUTcroTA6GO7LgY7igFKpMuIX1xcOJtqDrlyYsrvTxT35HDoI7fXStg4RrkbXEBvtHXCQ4PoRu3XNubc44YJeUwtzr3MPYNhEljtgIgCGhVzpK1Fk4IzMIjFvTVQ1jfzaCtDkdYaFxvxkWdjd9AXDJwqsBcJXW5WuNtAA8H60A50-ZYQMPyUaAwP0N-q45YvEWx3lpOPpoyU_8hiqBLa28VAsHmtvCtRZDJN_rhm4rlexCJnapPbs4ldIXXR_uB7tBWnB0DwzIonXiGHJVf8jaxQQehcyfn0nhgXdrU4LezWEbq2DfNu1V_DIoV0HGrr0GiPYPmj7IuyK0mAsSJJ-MAhO9cQ09EDW9eTXdUwUkWAaeZiTPc3ClFq6tR5Pev_N6nHOkp0jGJb2ND1YUgO6ozqkyrO9vGXld6ALTPu8FndWP8F7Zpfh00luKxuz9gDeR2ONjW0eGb6bUJ_NLlWPp_-G86a2pA2qEOLXkITa9OyrY4wrhAITdLM1JGmeIfaQzdzcqMJUed40_gGwZdbC-IyxN25hBoI8s0O2M7dUZ7jw5__DSxVDA1aePuxNk-0OIJ3xnce--ZEC85Jq0ATw6MLvXnQ34",
      "Content-Type": "application/json",
    },
  });
  try {

    yield put({ type: "GENERAL-LOADING-EMP" });
    let resp = yield AxiosInstanceUser.get(
      "/api/v1/user_statuses"
    );
    yield put({ type: "GET-EMP-STATUES-REDUCER", payload: resp.data.data });
    yield put({ type: "GENERAL-DONE" });

    yield put({ type: "GENERAL-DONE-EMP" });
  } catch (e) {
    store.dispatch({ type: OFFLINE });
    yield put({ type: "GENERAL-DONE-EMP" });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchgetStatues() {
  yield takeEvery("GET-EMP-STATUES", getStatues);
}
export function* EmployeeSaga() {
  yield all([
    fork(watchGetAllDepartments),
    fork(watchUpdDepartments),
    fork(watchAddDepartments),
    fork(watchGetAsync),
    fork(watchGetAsyncReg),
    fork(watchGetEmp),
    fork(watchGetAsyncRegCount),
    fork(watchGetAsyncWorkOffice),
    fork(watchGetAsyncLis),
    fork(watchgetStatues)
  ]);
}
