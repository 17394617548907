import './App.css'
import { useContext, useEffect } from "react"
import { AbilityContext } from "./config/acl/Can"
import 'react-toastify/dist/ReactToastify.css';
import Router from './router';
function App() {
  const ability = useContext(AbilityContext);

  useEffect(() => {
    ability.update([
      {
        subject: "all",
        action: "manage",
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Router />
  );
}

export default App;
