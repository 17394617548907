export default function BarScan() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Group_11592" data-name="Group 11592" transform="translate(-360 -118)">
            <g id="Mask_Group_386" data-name="Mask Group 386" transform="translate(360 118)">
                <g id="barcode-scanner-4">
                    <path id="Path_21648" data-name="Path 21648" d="M4.792,13.785V12.222H12.6v1.563a.625.625,0,0,1-.625.625H5.417A.625.625,0,0,1,4.792,13.785Z" transform="translate(-1.198 -3.056)" fill="#b9d2ff" />
                    <path id="Path_21649" data-name="Path 21649" d="M4.792,8.073V5.417a.625.625,0,0,1,.625-.625h6.563a.625.625,0,0,1,.625.625V8.073Z" transform="translate(-1.198 -1.198)" fill="#b9d2ff" />
                    <path id="Path_21650" data-name="Path 21650" d="M18.073,4.375a.469.469,0,0,1-.469-.469V1.719a.782.782,0,0,0-.781-.781H14.635a.469.469,0,0,1,0-.937h2.187a1.72,1.72,0,0,1,1.719,1.719V3.906A.469.469,0,0,1,18.073,4.375Z" transform="translate(-3.542)" fill="#404040" />
                    <path id="Path_21651" data-name="Path 21651" d="M16.823,18.542H14.635a.469.469,0,1,1,0-.937h2.187a.782.782,0,0,0,.781-.781V14.635a.469.469,0,1,1,.938,0v2.187A1.72,1.72,0,0,1,16.823,18.542Z" transform="translate(-3.542 -3.542)" fill="#404040" />
                    <path id="Path_21652" data-name="Path 21652" d="M3.906,18.542H1.719A1.72,1.72,0,0,1,0,16.823V14.635a.469.469,0,0,1,.938,0v2.187a.782.782,0,0,0,.781.781H3.906a.469.469,0,1,1,0,.938Z" transform="translate(0 -3.542)" fill="#404040" />
                    <path id="Path_21653" data-name="Path 21653" d="M.469,4.375A.469.469,0,0,1,0,3.906V1.719A1.72,1.72,0,0,1,1.719,0H3.906a.469.469,0,1,1,0,.938H1.719a.782.782,0,0,0-.781.781V3.906A.469.469,0,0,1,.469,4.375Z" fill="#404040" />
                    <path id="Path_21654" data-name="Path 21654" d="M11.823,14.792H5.26A1.1,1.1,0,0,1,4.167,13.7V12.135a.469.469,0,1,1,.938,0V13.7a.156.156,0,0,0,.156.156h6.563a.156.156,0,0,0,.156-.156V12.135a.469.469,0,0,1,.938,0V13.7A1.1,1.1,0,0,1,11.823,14.792Z" transform="translate(-1.042 -2.917)" fill="#404040" />
                    <path id="Path_21655" data-name="Path 21655" d="M12.448,7.917a.469.469,0,0,1-.469-.469V5.26a.156.156,0,0,0-.156-.156H5.26A.156.156,0,0,0,5.1,5.26V7.448a.469.469,0,0,1-.937,0V5.26A1.1,1.1,0,0,1,5.26,4.167h6.563A1.1,1.1,0,0,1,12.917,5.26V7.448A.469.469,0,0,1,12.448,7.917Z" transform="translate(-1.042 -1.042)" fill="#404040" />
                    <path id="Path_21656" data-name="Path 21656" d="M13.281,12.6H2.969a.469.469,0,0,1,0-.937H13.281a.469.469,0,0,1,0,.938Z" transform="translate(-0.625 -2.917)" fill="#404040" />
                </g>
            </g>
        </g>
    </svg>
    )
}