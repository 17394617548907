import * as types from "./constants"

export const Signup = (payload) => {
  return {
    type: types.SIGNUP_SAGA,
    payload,
  };
};
export const SignupSucces = (payload) => {
  return {
    type: types.SIGNUP_REDUCER,
    payload,
  };
};
export const Forget = (payload) => {
  return {
    type: types.FORGET_SAGA,
    payload,
  };
};
export const ForgetSucces = (payload) => {
  return {
    type: types.FORGET_REDUCER,
    payload,
  };
};
export const wait = () => {
  return {
    type: types.WAIT,
  };
};
export const done = () => {
  return {
    type: types.DONE,
  };
};
export const error = () => {
  return {
    type: types.ERROR,
  };
};
export const set_session = (payload) => {
  return {
    type: "SESSION",
    payload,
  };
};
export const verify = (payload) => {
  return {
    type: types.VERIFY,
    payload,
  };
};
export const send_sms = (payload) => {
  return {
    type: types.SMS,
    payload,
  };
};
export const set_token = (payload) => {
  return {
    type: "TOKEN",
    payload,
  };
};
export const register = (payload) => {
  return {
    type: "REGISTER",
    payload,
  };
};
export const _setCountries = (payload) => {
  return {
    type: "STORE_COUNTRIES",
    payload,
  };
};
export const _getCountriesSaga = () => {
  return {
    type: types.GET_COUNTRIES_SAGA,
  };
};
export const SetImage = (file) => {
  return {
    type: "UPLOAD-IMAGE",
    file,
  };
};