export default function PackageCart(props) {
    return (<svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
        <g id="Mask_Group_321" data-name="Mask Group 321">
            <g id="_07_Pickup" data-name="07 Pickup" transform="translate(0.023 0)">
                <path id="Path_21437" data-name="Path 21437" d="M9.829,8.36a.591.591,0,0,0,.418-.723L9.356,4.3a.592.592,0,0,0-.724-.417L5.3,4.777a.593.593,0,0,0-.418.723l.909,3.391a.534.534,0,0,0,.654.377Z" transform="translate(-1.865 -1.893)" fill={props.color2} />
                <path id="Path_21439" data-name="Path 21439" d="M11.686,9.172l-.625-.361a1.2,1.2,0,0,0-.911-.121l-3.216.862c-.058-.128.087-.119-.219-.358a4.437,4.437,0,0,0-1.005-.6c-.364-.153-.3-.031-.451-.016L3.825,3.235a1.2,1.2,0,0,0-.56-.73l-1-.577A.267.267,0,1,0,2,2.391l1,.577a.662.662,0,0,1,.314.4L4.743,8.724a1.657,1.657,0,1,0,2.329,1.344l3.216-.862a.661.661,0,0,1,.506.067l.625.361c.128.074.395-.389.267-.463Zm-5.97,2.153A1.128,1.128,0,1,1,6.4,10.8a1.128,1.128,0,0,1-.688.525Z" transform="translate(-1.865 -1.893)" fill={props.color1} />
            </g>
        </g>
    </svg>
    )
}

