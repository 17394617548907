export default function Package(props) {
    let icons_color = props.icons_color
    let icon_has_check = props.icon_has_check
    if (icon_has_check) {
        return (<svg id="_20x20" data-name="20x20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">

            <g id="Mask_Group_300" data-name="Mask Group 300">
                <g id="prosse" transform="translate(0.229)">
                    <path id="Path_21382" data-name="Path 21382" d="M10.655,15.816A5.51,5.51,0,0,1,19.9,11.769q.039-2.824-.047-5.649a3.791,3.791,0,0,0-1.985-3.234c-.629-.332-1.281-.652-1.939-.955A87.966,87.966,0,0,0,7.2,5.258,1.736,1.736,0,0,0,6.171,6.769c-.048,1.484-.063,3.016-.043,4.531a.208.208,0,0,1-.375.13Q5.264,10.8,4.8,10.194a.156.156,0,0,0-.207-.043l-.711.425a.2.2,0,0,1-.292-.187c-.008-1.509.03-3.022.11-4.488a1.8,1.8,0,0,1,1-1.484A68.466,68.466,0,0,1,13.477.89C12.852.646,12.23.422,11.624.209A3.886,3.886,0,0,0,9.1.209,45.06,45.06,0,0,0,2.851,2.885,3.794,3.794,0,0,0,.865,6.12q-.12,3.879,0,7.757a3.792,3.792,0,0,0,1.985,3.234A44.963,44.963,0,0,0,9.1,19.787a3.886,3.886,0,0,0,2.524,0q.265-.091.536-.189a5.471,5.471,0,0,1-1.505-3.783Z" transform="translate(-0.806 0.002)" />
                    <path id="Path_21383" data-name="Path 21383" d="M44.393,41.019A4.182,4.182,0,1,0,48.576,45.2a4.182,4.182,0,0,0-4.183-4.182Zm2.385,3.49-2.514,2.64a.736.736,0,0,1-.53.229h0a.739.739,0,0,1-.529-.224l-1.334-1.374a.738.738,0,1,1,1.059-1.027l.8.824,1.985-2.084a.737.737,0,0,1,1.068,1.016Z" transform="translate(-29.034 -29.383)" fill={icons_color} />
                </g>
            </g>
        </svg>)
    } else {
        return (<svg id="_20x20" data-name="20x20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
            <g id="Mask_Group_298" data-name="Mask Group 298" clip-path="url(#clip-path)">
                <g id="Group_10637" data-name="Group 10637" transform="translate(-87.023 -82.376)">
                    <path id="Path_21382" data-name="Path 21382" d="M10.618,15.761a5.491,5.491,0,0,1,9.217-4.033q.039-2.815-.047-5.63A3.778,3.778,0,0,0,17.81,2.876c-.627-.331-1.276-.65-1.933-.952a87.664,87.664,0,0,0-8.7,3.317A1.73,1.73,0,0,0,6.149,6.746c-.048,1.479-.063,3.005-.043,4.515a.207.207,0,0,1-.374.129q-.487-.623-.951-1.232a.156.156,0,0,0-.207-.042l-.709.424a.2.2,0,0,1-.291-.186c-.008-1.5.03-3.011.11-4.473A1.79,1.79,0,0,1,4.678,4.4,68.231,68.231,0,0,1,13.43.887C12.807.643,12.188.42,11.584.208a3.873,3.873,0,0,0-2.515,0A44.905,44.905,0,0,0,2.841,2.875,3.781,3.781,0,0,0,.862,6.1q-.119,3.865,0,7.731a3.779,3.779,0,0,0,1.978,3.223,44.809,44.809,0,0,0,6.228,2.667,3.873,3.873,0,0,0,2.515,0q.264-.091.534-.188a5.452,5.452,0,0,1-1.5-3.77Z" transform="translate(86.448 82.378)" />
                    <path id="Path_21383" data-name="Path 21383" d="M44.241,40.878a4.168,4.168,0,1,0,4.168,4.168,4.168,4.168,0,0,0-4.168-4.168Z" transform="translate(58.317 53.094)" fill={icons_color} />
                </g>
            </g>
        </svg>
        )
    }
}


