import BulkIcon from "./svg/bulk_icon"
import PackageCart from "./svg/package_cart"
import SmallCountryIcon from "./svg/small_iraq_icon"
import Product from "./svg/products"
import Kilogram from "./svg/kilogram"
import Vweight from "./svg/v_weight"
import Time from "./svg/time"
import Order from './svg/order'
import { useSelector, useDispatch } from "react-redux"

export default function PickUp(props) {
    const dispatch = useDispatch()
    let pallet = props.pallet
    let country = props.countries.filter(country => country.id === pallet.country_id)[0]
    let city = props.countries.filter(country => country.id === pallet.country_id)[0]?.sub_regions.filter(city => city.id === pallet.city_id)[0]
    let status = props.mainStatuses.filter(status => status.id === pallet.main_status_id)[0]
    let filter = props.mainStatuses.filter(status => status.id === pallet.main_status_id)[0]?.sub_statuses?.filter(filter => filter.id === pallet.pallet_status_id)[0]
    const pickup_approve = useSelector(state => state.shipping.pickup_approve)
    const orders_to_approve = useSelector(state => state.shipping.orders_to_approve)

    return (<div className={`bulk_element_with_status_selected`} onClick={() => {
        dispatch({ type: "CLOSE_PICKUP" })
    }}>
        <div className="bulk_element" style={{ backgroundColor: pickup_approve ? "#F4F8FF" : "#F7F7F7", border: pickup_approve ? "0.5px solid #7C90FF" : "0.5px solid #DDDDDD" }}>
            <div className="bulk_element_name">
                <div className="bulk_element_name_icon">
                    <div className="bulk_icon">
                        <BulkIcon color={status ? status.icons_color : "#ffffff"} />
                    </div>
                    <div className="bulk_name">
                        {pallet.ShipmentNumber}
                    </div>
                </div>
                <div className="bulk_element_right_icon" style={{ color: filter ? filter.color2 : "#ffffff" }}>
                    {filter.translations[0]?.name}
                    <PackageCart color1={filter ? filter.color1 : "#ffffff"} color2={filter ? filter.color2 : "#ffffff"} />
                </div>
            </div>
            <div className="bulk_element_data">
                <div className="bulk_element_country_data">
                    <div className="bulk_element_country_icon">
                        <SmallCountryIcon />
                    </div>
                    <div className="bulk_element_country_name">
                        {country ? country.region_translations[0]?.region_name : "iraq"}, {city ? city.region_translations[0]?.region_name : "Erbil"}
                    </div>
                </div>
                <div className="bulk_element_detail_data">
                    <Product icons_color="#A7EDCB" />
                    <div className="products_order_count">{pallet.products_count}/{pallet.orders.length}</div>
                    <Kilogram />
                    <div className="bulk_element_weight">{pallet.weight}</div>
                    <Vweight />
                    <div className="bulk_element_weight">{pallet.v_weight}</div>
                    <Time color="#8E8E8E" />
                    <div className="bulk_element_time">
                        <div className="bulk_element_time_num"> 3&nbsp;</div>
                        <div className="bulk_element_time_unit" style={{ textTransform: "lowercase" }}> h </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={pickup_approve ? 'bulk_orders_area_approved' : 'bulk_orders_area'}>
            {pallet.orders?.map((order, index) => <div key={index} className={pickup_approve ? (
                orders_to_approve.filter(one => ((one.num === order.ShipmentNumber) && one.approved)).length > 0 ? 'bulk_orders_area_element_approved' : 'bulk_orders_area_element')
                : 'bulk_orders_area_element'}>
                <div className='bulk_order_icon_name'>
                    <div className='bulk_order_icon'> <Order /></div>
                    <div className='bulk_order_name'>{order.ShipmentNumber}</div>
                </div>
                <div className='bulk_order_details'>
                    <div>{order.products_count}</div>
                    <div>{order.weight}</div>
                    <div>{order.v_weight}</div>
                    <div className='bulk_order_time'>1&nbsp;d</div>
                </div>
            </div>)}
        </div>
    </div>
    )
}