export const genLang = {
    en: {

    },
    ar: {

    },
    tr: {

    }
}