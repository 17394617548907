import { all, fork, put, takeEvery } from "@redux-saga/core/effects"
import * as types from "./constants"
import {
  register,
  SignupSucces
} from "./actions"
import AxiosInstance from "../../helpers/axiosInstance"
import { toast } from "react-toastify"

function* login(action) {
  try {
    yield put({ type: "loading" })
    let res = yield AxiosInstance.post(process.env.REACT_APP_BASE_URL + "/api/v1/auth/employee_login", JSON.stringify(action.payload))
    yield put({ type: "SET_TOKEN", user: res.data.data, token: res.data.data.access_token })
    let asd = new Date();
    let a = `${asd.getHours() > 9
      ? asd.getHours().toString()
      : "0" + asd.getHours().toString()
      }:${asd.getMinutes() > 9
        ? asd.getMinutes().toString()
        : "0" + asd.getMinutes().toString()
      }`;
    localStorage.setItem("session-start", a);
    yield put({ type: "loaded" })
  } catch (e) {
    toast.error(e.response?.data?.message)
    yield put({ type: "loaded" })
    yield put({ type: "ERROR" })
  }
}

function* watchlogin() {
  yield takeEvery("LOGIN_SAGA", login);
}

function* Signup(action) {
  let response;
  try {
    yield put({ type: "loading" })
    response = yield AxiosInstance.post(
      process.env.REACT_APP_BASE_URL + "/api/v1/auth/signup",
      JSON.stringify({
        username: action.payload.name,
        role_id: 2,
        id_token: action.payload.id,
        password: action.payload.pass,
      })
    );
    yield put(register(response.data.data));
    yield put({ type: "loaded" })
  } catch (e) {
    yield put({ type: "loaded" })
  }
}
function* watchSignup() {
  yield takeEvery(types.SIGNUP_SAGA, Signup);
}
function* Forget(action) {
  yield put({ type: "loading" })
  let response;
  try {
    response = yield AxiosInstance.post(process.env.REACT_APP_BASE_URL + "/api/v1/auth/resetPasswordUsingOTP",
      JSON.stringify({
        id_token: action.payload.id,
        password: action.payload.pass,
      })
    );
    yield put(SignupSucces(response.data.data));
    yield put({ type: "loaded" })
  } catch (e) {
    yield put({ type: "loaded" })
    if (e instanceof Error) {
    }
  }
}
function* watchForget() {
  yield takeEvery(types.FORGET_SAGA, Forget);
}

export function* AuthSaga() {
  yield all([
    fork(watchSignup),
    fork(watchlogin),
    fork(watchForget)
  ]);
}
