// import { ONLINE, OFFLINE } from 'redux-offline-status/lib/actions'

export function generalReducer(state = {
    loading: false,
    lang: "en",
    offline: false
}, action) {
    switch (action.type) {
        case "loading": {
            return ({ ...state, loading: true })
        }
        case "loaded": {
            return ({ ...state, loading: false })
        }
        default: {
            return ({
                ...state
            })
        }
    }
}