import { useSelector, useDispatch } from "react-redux"
import './barscan.css'
import { useState } from "react";
import SwitchIcon from "./svgs/switch_icon";
import ScanIcon from "./svgs/scanIcon";
import { BarcodeScanner } from "../Dashboard/Routes/shipments/barCodeReader"
import InnerScan from "./svgs/inner_scan_icon";
import InnerSend from "./svgs/inner_send_icon";
import InputBegin from "./svgs/input_begin";

export default function OrderBarScan() {
    const pickup_id = useSelector(state => state.shipping.pickup_id)
    const [mode, setMode] = useState("camera")
    const [approved, setApproved] = useState(false)
    const [value, setValue] = useState("")
    const dispatch = useDispatch()
    if (pickup_id) {
        if (mode === "camera") {
            return (<div className="barscan_down_area">
                <div className="barscan_switch_area">
                    <div className="barscan_switch_button" onClick={() => setMode("keyboard")}>
                        <SwitchIcon />
                    </div>
                    <div className="barscan_description">
                        <ScanIcon />
                        {approved ? "Scan Order Barcode" : "Scan Pallet Barcode"}
                    </div>

                </div>
                <BarcodeScanner />
            </div>)
        } else {
            return (<div className="barscan_down_area">
                <div className="enter_bartext_area">
                    <div className="inner_scan_icon" onClick={() => setMode("camera")}><InnerScan /></div>
                    <div className="inner_search_area">
                        <div className="inner_search_area_icon">
                            <div className="inner_search_area_icon_o">{approved ? "TD" : "P"}</div>
                        </div>
                        <input
                            id="inner_search_area_input"
                            value={value}
                            className="inner_search_area_text"
                            placeholder={approved ? " Enter Order Barcode" : " Enter Pallet Barcode"}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </div>
                    <div className="inner_send_icon" onClick={() => {
                        if (approved) {
                            dispatch({ type: "PICKUP_ORDER_APPROVE", payload: value })
                            setValue("")
                        } else {
                            setValue("")
                            setApproved(true)
                            dispatch({ type: "PICKUP_PALLET_APPROVE" })
                        }
                    }}><InnerSend /></div>
                </div>
            </div>)
        }

    }
    else {
        return (<></>)
    }
}