import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import { ADD_DEPT_URL, GET_DEPT_URL, UPD_DEPT_URL } from "./constants";
import store from "../../store";
function* getAllDepartments(action) {
  let response;
  try {
    response = yield AxiosInstance.get(process.env.REACT_APP_BASE_URL + GET_DEPT_URL
    )
    yield put({ type: "GET-CUR-REDUCER", payload: response.data.data });
  } catch (e) {
    toast.error("failed! BACK END ERROR");
  }
}

function* watchGetAllDepartments() {
  yield takeEvery("GET-CUR", getAllDepartments);
}
function* AddDepartments(action) {
  let responses;
  try {

    yield put({ type: "request-running-curr", payload: action.payload });
    yield AxiosInstance.post(process.env.REACT_APP_BASE_URL +

      ADD_DEPT_URL,
      JSON.stringify({
        ...action.payload,
        is_main: "0",
        is_used_in_system: "1",
        symbol: "null",
        equivalent_to_main_currency: "1",
      })
    );
    yield put({ type: "request-running-curr-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-curr-red", payload: null });
    }, 4000);

    responses = yield AxiosInstance.get(process.env.REACT_APP_BASE_URL +

      GET_DEPT_URL
    );
    yield put({ type: "GET-CUR-REDUCER", payload: responses.data.data });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-curr",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddDepartments() {
  yield takeEvery("ADD-CUR", AddDepartments);
}
function* UpdateDepartments(action) {
  let responses;
  try {

    yield put({ type: "request-running-curr", payload: action.payload });
    yield AxiosInstance.post(process.env.REACT_APP_BASE_URL +

      UPD_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-curr-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-curr-red", payload: null });
    }, 4000);

    responses = yield AxiosInstance.get(process.env.REACT_APP_BASE_URL +

      GET_DEPT_URL
    );

    yield put({ type: "GET-CUR-REDUCER", payload: responses.data.data });

  } catch (e) {
    yield put({
      type: "GENERAL-ERR-curr",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdDepartments() {
  yield takeEvery("UPD-CUR", UpdateDepartments);
}

export function* CurrSaga() {
  yield all([
    fork(watchGetAllDepartments),
    fork(watchUpdDepartments),
    fork(watchAddDepartments),
  ]);
}
