import { combineReducers } from "redux";
import { offline } from 'redux-offline-status/lib/reducer'
import { generalReducer } from "./general/reducer"
import { authReducer } from "./auth/reducer"
import categoriesReducer from "./categories/reducer"
import { LangReducer } from "./LinkedLanguages/reducer"
import { EmployeeReducer } from "./HRM/employee/reducer"
import { HRMReducer } from "./HRM/departments/reducer"
import { JobReducer } from "./HRM/JobTitle/reducer"
import { CountriesReducer } from "./countries/countries/reducer"
import { CompainesReducer } from "./countries/companies/reducer"
import { OfficesReducer } from "./countries/offices/reducer"
import { LiscensesReducer } from "./countries/Liscense/reducer"
import { tablePropsInit } from './translate/tableReducer'
import { translationsReducer } from './translate/translationsReducer'
import { CurrReducer } from "./Currenciess/currencies/reducer"
import { NumReducer } from "./Currenciess/numcats/reducer"
import { shppingReducer } from "./shipment/index"

const rootReducer = combineReducers({
  offline,
  auth: authReducer,
  general: generalReducer,
  categories: categoriesReducer,
  langs: LangReducer,
  employee: EmployeeReducer,
  department: HRMReducer,
  jobs: JobReducer,
  countries: CountriesReducer,
  companies: CompainesReducer,
  offices: OfficesReducer,
  liscenses: LiscensesReducer,
  tablePropsInit,
  translationsReducer,
  currency: CurrReducer,
  numcats: NumReducer,
  shipping: shppingReducer
})

export default rootReducer
