import { BrowserRouter, Switch, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import Signup from "./components/auth/Signup"
import MainPage from "./components/Dashboard/MainPage/index"
import Loading from "./loading"
import { useSelector } from "react-redux"
import store from "./redux/store"
import { BarcodeScanner } from "./components/Dashboard/Routes/shipments/barCodeReader"
import MainStatuses from './components/Dashboard/Routes/shipments/main_statuses'
import Barcode from "./assets/svg/barcode"

export const isUserLoggedIn = () => (store?.getState()?.auth?.token ? true : false)

export default function Router() {
    const loading = useSelector(state => state.general.loading)
    const barscan = useSelector(state => state.shipping?.barscan)
    const mainStatuses = useSelector(state => state.shipping.mainStatuses)

    return (<BrowserRouter basename={"/"}>
        {loading && <Loading />}
        <ToastContainer />
        {barscan && <div className="blur-effect-bar">
            <div className="content-area-bar-scan">
                {(mainStatuses.length > 0) && <MainStatuses statuses={mainStatuses} activeStatus={null} barcodeopened={true} setActiveStatus={(e) => console.log(e)} />}
                <div className="scan-bar-code-title">
                    <Barcode />
                    <div className="scan-bar-code-title-text"> Scan Barcode</div>
                </div>
                <BarcodeScanner />
            </div>
        </div>}
        <Switch>
            <>
                {localStorage.getItem('user') ? <Route exact path={`/`} component={MainPage} /> :
                    <Route exact path={`/`} component={Signup} />
                }
                {localStorage.getItem('user') && <Route exact path={`/:route`} component={MainPage} />}
                <Route exact path={`/auth/signup`} component={Signup} />
            </>
        </Switch>
    </BrowserRouter>)
}